.ea-footer-component {
    --c-base: #{$c-grey-lightest};
    --bg-base: #{$c-brand-fifth};
    --footer-height: #{$footer-height};
    --footer-padding: 0 #{sizer(7.5)} #{sizer(6)} #{sizer(7.5)};

    display: flex;
    flex: 1 0 var(--footer-height);
    min-height: var(--footer-height);
    max-height: var(--footer-height);
    color: var(--c-base);
    background-image: linear-gradient(359deg, $c-brand-fifth 78.5%, $c-grey-lightest 79%);

    .ea-footer {
        // Prefix
        &__container {
            display: flex;
            width: 100%;
            font-family: $ff-secondary;
            margin-top: auto;
            padding: var(--footer-padding);

            @include tablet {
                --footer-padding: 0 #{sizer(2)} #{sizer(1)} #{sizer(2)};
            }
        }

        &__social {
            display: flex;
            flex: 1 0 100%;
            padding-bottom: sizer(2);

            @include media-breakpoint(sm) {
                margin: 0 auto;
                max-width: 80%;
                justify-content: center;
            }

            &-item {
                &:not(:first-child) {
                    margin-left: sizer(1.5);
                }
            }

            &-link {
                --c-social-link: #{$c-grey-dark};
                --bg-social-link: #{$c-bright};

                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--c-social-link);
                background: var(--bg-social-link);
                transition: all 0.3s ease;

                @include circle(sizer(4));

                &:hover {
                    --c-social-link: #{$c-brand-second};
                    --bg-social-link: #{$c-bright};
                }
            }
        }

        &__nav {
            display: flex;
            justify-content: center;

            @include media-breakpoint(sm) {
                order: -1;
                padding-bottom: sizer(2);
            }

            &-list {
                display: flex;
                margin-left: auto;

                @include media-breakpoint(sm) {
                    margin-right: auto;
                }
            }

            &-item {
                margin-right: sizer(7.25);
                line-height: calc-em(32, m);

                @include media-breakpoint(sm) {
                    margin-right: sizer(2);

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            &-link {
                --c-social-link: #{$c-grey-lightest};

                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                color: var(--c-social-link);
                transition: all 0.3s ease;

                &:hover {
                    --c-social-link: #{$c-bright};
                }

                @include media-breakpoint(sm) {
                    font-size: fs(xxs);
                    line-height: 130%;
                    text-align: center;
                }
            }
        }

        &__copyright {
            width: 100%;
            font-size: fs(xxs);
            color: var(--c-base);

            @include media-breakpoint(sm) {
                text-align: center;
            }
        }
    }
}
