//---------------
// ## Breakpoints
//---------------

@mixin mobile {
    @media screen and (max-width: $mobile-landscape - 1px) {
        /* mobile */
        @content;
    }
}

@mixin mobile-landscape {
    @media screen and (max-width: $tablet-landscape - 1px) {
        /* mobile landscape */
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: $tablet-landscape - 1px) {
        /* tablet */
        @content;
    }
}

@mixin tablet-landscape {
    @media screen and (max-width: $tablet-landscape) {
        /* tablet landscape */
        @content;
    }
}

@mixin desktop-small {
    @media screen and (max-width: $desktop-small) {
        /* desktop small */
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $desktop-small + 1px) {
        /* desktop */
        @content;
    }
}

@mixin desktop-min {
    @media screen and (max-width: $desktop - 1px) {
        /* desktop min */
        @content;
    }
}

//---------------
// ## Browser
//---------------

// ## Internet Explorer
// $$ IE ≥ 11
@mixin ie-media {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

// $$ IE ≥ 9
// Deprecate
@mixin ie9-media {
    @media screen and (min-width: 0 \0) {
        @content;
    }
}

// $$ IE 10
@mixin ie10-media {
    @supports (-ms-ime-align: auto) {
        @content;
    }
}

// ## Edge
@mixin edge-media {
    @media screen {
        @media (min-width: 0) {
            @content;
        }
    }
}

@mixin edge-selector($selector) {
    _:-ms-lang(x),
    _:-webkit-full-screen,
    #{'.' + '#{$selector}'} {
        @content;
    }
}

// Usage:
// @include only_ie9( '.my_element', (color: red))

// ## FIREFOX
// $$ Firefox ≥ 3
@mixin ff-3 {
    @media \0 all {
        @content;
    }
}

// $$ Firefox ≥ 3.6
@mixin ff3-6-media {
    @media screen and (-moz-images-in-menus: 0) {
        @content;
    }
}

// $$ Firefox ≥ 4
@mixin ff4-media {
    @media screen and (min--moz-device-pixel-ratio: 0) {
        @content;
    }
}

// $$ Firefox ≥ 8
@mixin ff8-media {
    @media all and (min--moz-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
        @content;
    }

    @media all and (-moz-images-in-menus: 0) and (min-resolution: 0.001dpcm) {
        @content;
    }
}

// $$ Firefox ≥ 11
@mixin ff11-media {
    @media all and (min--moz-device-pixel-ratio: 0) {
        @media (min-width: 0) {
            @content;
        }
    }

    @media all and (-moz-images-in-menus: 0) {
        @media (min-width: 0) {
            @content;
        }
    }
}

// $$ Firefox ≥ 29
@mixin ff29-media {
    @media all and (min--moz-device-pixel-ratio: 0) and (min-resolution: 3 e1dpcm) {
        @content;
    }
}

// $$ Firefox all Windows version
@mixin ff-allwin {
    @media (-moz-os-version: windows-xp),
        (-moz-os-version: windows-vista),
        (-moz-os-version: windows-win7),
        (-moz-os-version: windows-win8) {
        @content;
    }
}

// $$ Firefox for all theme Windows version
@mixin ff-win-theme {
    @media screen and (-moz-windows-theme) {
        @content;
    }
}

// $$ Firefox for windows compositor
@mixin ff-win-compositor {
    @media not all and (-moz-windows-compositor) {
        @content;
    }
}

// $$ Firefox windows XP
@mixin ff-xp {
    @media (-moz-os-version: windows-xp) {
        @content;
    }
}

// $$ Firefox windows Vista
@mixin ff-vista {
    @media (-moz-os-version: windows-vista) {
        @content;
    }
}

// $$ Firefox windows 7
@mixin ff-win7 {
    @media (-moz-os-version: windows-win7) {
        @content;
    }
}

// $$ Firefox windows 7
@mixin ff-win8 {
    @media (-moz-os-version: windows-win7) {
        @content;
    }
}

// $$ Firefox for linux
@mixin ff-linux {
    @media not all and (-moz-windows-compositor) {
        @content;
    }
}

@mixin ff-linux-pixelratio {
    @media not all and (-moz-device-pixel-ratio) {
        @content;
    }
}

// ## Safari
@mixin safari {
    @media only screen and (-webkit-min-device-pixel-ratio: 0) {
        @content;
    }
}

// ## Chrome iPhone
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
}

// ## Orientation
@mixin landscape {
    @media only screen and (orientation: landscape) {
        @content;
    }
}

@mixin portrait {
    @media only screen and (orientation: portrait) {
        @content;
    }
}

//---------------
// ## Print
//---------------

@mixin print {
    @media print {
        *,
        *::before,
        *::after,
        *::first-letter,
        p::first-line,
        div::first-line,
        blockquote::first-line,
        li::first-line {
            background: transparent !important;
            color: #000 !important;
            box-shadow: none !important;
            text-shadow: none !important;
        }

        a,
        a:visited {
            text-decoration: underline;
        }

        a[href]::after {
            content: ' (' attr(href) ')';
        }

        abbr[title]::after {
            content: ' (' attr(title) ')';
        }

        a[href^='#']::after,
        a[href^='javascript:']::after {
            content: '';
        }

        pre {
            white-space: pre-wrap !important;
        }

        pre,
        blockquote {
            border: 1px solid #999;
            page-break-inside: avoid;
        }

        thead {
            display: table-header-group;
        }

        tr,
        img {
            page-break-inside: avoid;
        }

        p,
        h2,
        h3 {
            orphans: 3;
            widows: 3;
        }

        h2,
        h3 {
            page-break-after: avoid;
        }
        @content;
    }
}

@mixin print-simple {
    @media print {
        @content;
    }
}

//---------------
// ## Devices
//---------------

// Retina screen
@mixin retina {
    @media only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
        @content;
    }
}

// Aural
@mixin aural {
    @media aural {
        @content;
    }
}

// Touchscreens
@mixin touchscreens {
    @media (hover: none) and (pointer: coarse) {
        @content;
    }
}

// Mouse & touch pad
@mixin mouse {
    @media (hover: hover) and (pointer: fine) {
        @content;
    }
}

// Device based in peripheral pens, touch blackboards or touch  projector
@mixin pointer-and-pen {
    @media (hover: none) and (pointer: fine) {
        @content;
    }
}

// Nintendo Wii controller, Microsoft Kinect
@mixin videoconsole {
    @media (hover: hover) and (pointer: coarse) {
        @content;
    }
}
