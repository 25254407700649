.ea-tag {
    cursor: pointer;
    min-width: sizer(13);
    height: sizer(4.5);
    background-color: $bg-tag;
    color: $fc-negative;
    font-family: $ff-base;
    font-size: fs(s);
    border-radius: sizer(1);
    text-align: center;
    padding: sizer(1) sizer(2);

    &::after {
        content: 'x';
        color: $fc-negative;
        margin-left: sizer(2);
    }

    &:hover,
    &:focus {
        background-color: $bg-tag-hover;
    }
}
