@import '@assets/scss/utils.scss';

// $$ vars

// Animations
$ea-private-transition-rhythm: 0.3s ease;

// Header
$ea-private-header-padding: sizer(6);
$ea-private-header-z-index: z('menu');

// header container
$ea-private-header-container-max-width: map-get($breakpoints, 'xl');

// Logo
$ea-private-header-logo-height: sizer(10);

// Title
$ea-private-header-title-size: sizer(3);

// Logo
$ea-private-header-language-size: sizer(2);

// btn menu user
$ea-private-header-btn-size: sizer(4);
$ea-private-header-btn-color-hover: $c-bright;
$ea-private-header-btn-bg-hover: $c-brand-quarter;
$ea-private-header-btn-bg-active: $c-brand-second;

// Avatar
$ea-private-header-avatar-size: sizer(5.25);

$curve-effect-height: sizer(6.25);

// ## Header app
.ea-private-header-component {
    --header-height: #{sizer(13.5)};
    --nav-height: #{sizer(17.5)};
    --logo-height: #{sizer(10)};
    --language-height: #{sizer(1.5)};
    --logo-margin: 0 auto 0 #{sizer(4)};

    position: fixed;
    z-index: $ea-private-header-z-index;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: all $ea-private-transition-rhythm;

    @include media-breakpoint(sm) {
        position: fixed;

        @include shadow(defaultcard);
    }

    @include animation--fade-in;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        max-height: var(--header-height);
        display: flex;
        background: $c-bright;

        @include media-breakpoint('sm') {
            display: none;
        }
    }

    &.header--min {
        --header-height: #{sizer(8)};
        --nav-height: #{sizer(7.5)};
        --item-height: #{sizer(7.5)};
        --logo-height: #{sizer(6)};
        --language-height: #{sizer(1.1)};
        --pointer-top: #{sizer(-3.5)};

        @include media-breakpoint(sm) {
            --header-height: #{rem(108px)};
            --nav-height: #{rem(140px)};
            --item-height: #{sizer(8.5)};
            --logo-height: #{sizer(10)};
        }

        + .ea-launcher-container {
            --launcher-top: #{sizer(20)} !important;
        }
    }

    // $$ Header
    // Prefix
    .ea-private-header {
        &__container {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: var(--header-height);
            background: $c-bright;
            transition: height $ea-private-transition-rhythm;

            // Breackpoints
            @include media-breakpoint(lg) {
                padding: 0 sizer(2);
            }

            @include media-breakpoint(sm) {
                height: sizer(8.5);
                padding: 0 sizer(2) 0 0;
                background: $c-bright;
            }
        }

        &__wrapper {
            position: relative;
            display: grid;
            align-items: center;
            grid-template-columns: 1fr 2fr 1fr;
            grid-gap: sizer(1);
            max-width: $ea-private-header-container-max-width;

            @include square(100%);

            @include media-breakpoint(sm) {
                display: flex;
            }
        }

        &__btn-menu {
            display: none;
            justify-content: center;
            align-items: center;
            flex: 0 1 $ea-private-header-btn-size * 1.1;
            border: none;
            color: $fc-heading;
            margin: 0 sizer(1.5) 0;
            background: none;
            cursor: pointer;
            transition: all $ea-private-transition-rhythm;

            @include circle($ea-private-header-btn-size * 1.1);

            &:hover {
                color: $ea-private-header-btn-color-hover;
                background: $c-brand-second;
            }

            &.is-active {
                transform: scale(0.75);
                color: $fc-heading;
                background: transparent;
            }

            @include media-breakpoint(sm) {
                display: flex;
                z-index: z(over-default);

                & ~ .ea-private-header__logo {
                    --logo-margin: 0 auto 0 #{sizer(1.5)};
                }
            }
        }

        &__logo {
            display: flex;
            justify-content: flex-start;
            height: var(--logo-height);
            transition: height $ea-private-transition-rhythm;

            @include media-breakpoint(sm) {
                --logo-height: #{sizer(5.5)};

                margin: var(--logo-margin);
                transform: translateX(-#{sizer(1)});
                z-index: z(over-default);
            }

            a {
                display: flex;
                height: 100%;
                margin-right: sizer(1.75);

                svg {
                    height: 100%;
                    width: auto;
                }
            }
        }
        &__language {
            
            font-size: var(--language-height);//$ea-private-header-language-size;
            font-weight: $fw-bold;
            color: $fc-quarter;
            justify-self: right;
            transition: font-size $ea-private-transition-rhythm;
            margin-left: 100px;           

           
        }

        &__secondary-logo {
            height: var(--logo-height);
            transition: height $ea-private-transition-rhythm;
            max-width: 0;

            object {
                height: 100%;
                width: auto;
                max-width: 8rem;
                object-fit: contain;
            }
        }

        &__title {
            font-family: $ff-secondary;
            font-size: $ea-private-header-title-size;
            font-weight: $fw-bold;
            color: $fc-quarter;
            justify-self: center;

            @include media-breakpoint(sm) {
                display: none;
            }
        }
    }
}

// ## Navigation Main
.ea-private-nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: var(--nav-height);
    background: url('../../images/bg_header.png') no-repeat top center;
    transition: height $ea-private-transition-rhythm;

    @include media-breakpoint(sm) {
        --nav-height: auto;

        position: fixed;
        top: 0;
        left: -100%;
        bottom: 0;
        width: sizer(37.5);
        background: $c-bright;
        transition: left $ea-private-transition-rhythm;

        @include shadow(defaultdcardhover);

        &::after {
            opacity: 0;
        }

        &.is-open {
            left: 0;

            &::after {
                content: ' ';
                position: fixed;
                top: 0;
                bottom: 0;
                width: 100%;
                opacity: 0;
                background: rgba($c-grey-dark, 0.2);
                z-index: z(below);

                @include animation(fadeIn, 1000ms, 0.8s);
            }
        }
    }

    &__list {
        display: flex;
        justify-content: center;
        max-width: $ea-private-header-container-max-width;
        margin: 0 auto;

        @include media-breakpoint(sm) {
            padding: var(--header-height) sizer(3) sizer(3) sizer(3);
            flex-direction: column;
            margin-bottom: auto;
            height: 100vh;
            width: 100%;
            justify-content: flex-start;
            background: $c-bright;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        height: var(--item-height, #{sizer(10.5)});
        font-size: fs(m);
        padding: var(--item-padding, #{0 sizer(3)});
        position: relative;

        .not-allowed {
            font-weight: $fw-extrabold;
            color: $fc-disabled;
            cursor: default;
        }

        &.nav-item-light {
            > a {
                background-color: $fc-negative;
                padding: sizer(1.5) sizer(3) sizer(1.5) sizer(3);
                border-radius: sizer(1);
                font-size: fs(s);
                font-weight: $fw-semibold;

                @include shadow(defaultdcardhover);

                &:hover {
                    @include shadow(container);
                }

                @include media-breakpoint(sm) {
                    padding: 0 0 0 sizer(1);
                }
            }
        }

        @include media-breakpoint(sm) {
            --item-height: #{sizer(4)};

            padding: var(--item-height) sizer(1);
            border-bottom: 2px solid $c-grey-light;
        }
    }

    &__link {
        font-family: $ff-secondary;
        font-weight: $fw-extrabold;
        text-decoration: none;

        &:hover {
            color: $fc-quarter;
        }

        @include media-breakpoint(sm) {
            --item-height: #{sizer(4)};

            display: flex;
            align-items: center;
            font-weight: $fw-regular;
            padding: var(--item-height) sizer(1);

            @include square(100%);
        }

        &.active {
            &::before {
                content: ' ';
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                // Triangle
                border-left: sizer(3) solid transparent;
                border-right: sizer(3) solid transparent;
                border-top: sizer(2.3) solid $c-bright;

                @include media-breakpoint(sm) {
                    display: none;
                }
            }
        }
    }
}

// Whit launcher

.home-header {
    position: relative;
    z-index: auto;

    .ea-private-nav {
        height: sizer(52.5);
        margin-bottom: sizer(13);
        align-items: center;
        position: relative;

        &::before {
            content: '';
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            background: url('../../images/hero-image.svg') no-repeat top center;
            background-size: 100%;
        }

        &__list {
            flex: 0;
        }

        &::after {
            background-image: linear-gradient(
                -2deg,
                $bg-base 0%,
                $bg-base 3.5vw,
                transparent 3.6vw
            );
            content: '';
            height: calc(100% - #{$curve-effect-height});
            left: 0;
            position: absolute;
            top: $curve-effect-height;
            width: 100%;
        }
    }

    + .home-main {
        padding-top: 0;
    }

    + .ea-launcher-container {
        --launcher-top: #{sizer(40)};

        position: absolute;
        top: var(--launcher-top);
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease;
        z-index: z(default);
    }
    // $$ States
    &.is-launcher-expanded {
        .ea-private-nav {
            overflow: hidden;
            &::before {
                top: -1rem;
                right: -1rem;
                bottom: -1rem;
                left: -1rem;
                background-size: 103%;
                filter: blur(8px);
                -webkit-filter: blur(8px);
            }
        }

        + .ea-launcher-container {
            --launcher-top: #{sizer(25)};

            ~ .home-main {
                padding-top: sizer(8);
            }
        }
    }
}

.ea-launcher-container {
    ~ .home-main {
        padding-top: 0;
        transition: all 0.3s ease;
    }
}
