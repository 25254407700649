$bc-checkbox: $bc-input;
$w-check: rem(6px);
$h-check: sizer(1.5);
$b-check: rem(2px) solid $bc-input-checked;
$b-width-check: 0 rem(2px) rem(2px) 0;
$s-checkbox: sizer(2.25);

.ea-checkbox {
    position: relative;
    display: flex;
    align-items: center;
    color: $fc-base;

    &__value {
        padding-left: ($w-check + sizer(2.5));
        line-height: calc-em(32, s);
        font-size: fs(xs);

        &::before {
            content: ' ';
            position: absolute;
            top: 0.35em;
            left: 0;
            display: block;
            width: $s-checkbox;
            min-width: $s-checkbox;
            height: $s-checkbox;
            cursor: pointer;
            border-radius: rem(3px);
            border: rem(2px) solid $bc-checkbox;
        }

        &::after {
            content: ' ';
            position: absolute;
            display: none;
            top: ($h-check/2);
            left: $w-check;
            width: $w-check;
            height: $h-check;
            border: $b-check;
            border-width: $b-width-check;
            transform: rotate(40deg);
            transition: opacity 0.3s ease-in;
            z-index: z(20);
        }
    }

    input[type='checkbox'] {
        position: absolute;
        opacity: 0;

        &:focus {
            + .ea-checkbox__value {
                &::before {
                    border-color: $bc-input-focus;
                }
            }
        }

        &:checked {
            ~ .ea-checkbox__value {
                &::before {
                    border-color: $bc-input-checked;
                }

                &::after {
                    display: block;
                }
            }
        }
    }
}
