@import '@assets/scss/utils.scss';

.requirements-country-option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    &__value {
        display: flex;
        flex: 1;
        align-items: center;
    }

    &__icon {
        --color-warning: #ff8100;
        --bg-warning: #fff6ad;
        --bg-error: #ffefef;

        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;

        @include circle(sizer(4));

        &.msg {
            &--error {
                background: var(--bg-error);

                .ea-icon-component {
                    svg {
                        --fill-svg: #{$c-red};
                    }
                }
            }

            &--warning {
                background: var(--bg-warning);

                .ea-icon-component {
                    svg {
                        --fill-svg: var(--color-warning);
                    }
                }
            }
        }
    }
}

.padding-vertical {
    padding-top: 5px;
    padding-bottom: 10px;;
}
