html {
    height: 100%;
    font-size: 100%;
    text-size-adjust: none;
}

body {
    margin: 0;
    height: 100%;
    line-height: $lh-base;
    font-family: $ff-base;
    color: $fc-base;
    overflow: hidden;
    overflow-y: auto;
    background: $c-grey-lightest;
}

#root {
    min-height: 100vh;
    min-width: 100vw;
}
