.ea-list-counter {
    counter-reset: ea-counter;

    > li {
        counter-increment: ea-counter;

        &::before {
            content: counter(ea-counter);
        }
    }
}
