@import '@assets/scss/utils.scss';

.with-input-label-component {
    font-size: fs(s);
    font-weight: $fw-bold;
    color: $c-grey-darkest;

    &.size {
        &--small {
            width: 25%;
            flex-grow: 1;
        }

        &--mid {
            flex-basis: 50%;
            flex-grow: 1;
        }

        &--big {
            flex-basis: 75%;
            flex-grow: 1;
        }

        &--full {
            flex-basis: 100%;
            flex-grow: 1;
        }
    }
}

.ea-input-label-component {
    &__input {
        position: relative;
        display: flex;
        flex: 1 0 auto;
        font-weight: $fw-regular;

        .ea-textfield {
            padding-right: sizer(3);
        }

        > .ea-icon-component {
            color: $fc-action;
            position: absolute;
            right: sizer(2);
            bottom: sizer(1.75);
            pointer-events: none;
        }
    }

    .ea-label {
        display: flex;

        &--optional {
            font-size: fs(xxs);
            margin-left: auto;
        }
    }

    &.has-error {
        .ea-label,
        .ea-icon-component {
            color: $fc-error;
        }
    }
}
