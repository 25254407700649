@import '@assets/scss/utils.scss';

.ea-select-multiple {
    width: 100%;

    &__input-container {
        display: flex;
    }

    &__selected-options {
        display: flex;
        flex-wrap: wrap;
        padding-top: sizer(3);

        .selected-option__item {
            display: flex;
            margin-bottom: sizer(1);

            &:not(:last-child) {
                margin-right: sizer(1);
            }
        }
    }
}
