%form-base {
    background-color: $bg-input;
    border-radius: sizer(1);
    border-style: solid;
    border-width: 2px;
    border-color: $bc-input;
    color: $fc-input;
    display: inline-block;
    font-family: $ff-base;
    font-size: fs(s);
    height: sizer(5.5);
    padding: 0 sizer(2);
    position: relative;
    width: 100%;

    @include color-placeholder($fc-placeholder);
}

.ea-textfield-component {
    position: relative;
    width: 100%;

    .ea-calendar {
        display: flex;
        justify-content: center;
        padding: sizer(1) 0 sizer(2.5) 0;
        min-width: sizer(38);
    }

    .ea-validate-msg {
        &--error {
            border-color: $fc-error !important;
            color: $fc-error;

            &:hover,
            &:focus {
                border-color: $fc-error;
                color: $fc-error;
            }

            & + span {
                color: $fc-error;
            }

            @include color-placeholder($fc-error);
        }
    }

    .ea-input-validation__message {
        position: absolute;
        line-height: 120%;
        padding-top: sizer(0.5);
        color: $fc-error;

        &.is-info {
            color: inherit;
        }
    }

    &.is-readonly {
        dt.is-readonly {
            padding-bottom: sizer(1);
        }

        dd.is-readonly {
            display: block;
            height: sizer(5.8);
            font-weight: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: sizer(28);
            padding: sizer(1) sizer(2) sizer(1) 0;
        }
    }

    .ea-label {
        font-size: fs(s);
        font-weight: $fw-semibold;
    }

    &.label {
        &--top {
            .ea-label {
                display: flex;
                padding-bottom: sizer(1);
            }
        }
    }

    .ea-textfield {
        @extend %form-base;

        &:hover,
        &:focus {
            border-color: $fc-action;
        }

        &--filled {
            border-color: $fc-action;
        }

        &:-internal-autofill-selected,
        &--disabled,
        &[disabled] {
            background-color: $bg-input;
            border-color: $bc-disabled;
            color: $fc-disabled;

            &:hover,
            &:focus {
                border-color: $bc-disabled;
                color: $fc-disabled;
            }
        }

        &--error {
            border-color: $fc-error;
            color: $fc-error;

            @include color-placeholder($fc-error);

            &:hover,
            &:focus {
                border-color: $fc-error;
                color: $fc-error;
            }
        }
    }
}
