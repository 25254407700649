a {
    --color-link: #{$c-brand-second};

    cursor: pointer;

    &:link,
    &:visited,
    &:active {
        color: var(--color-link, #485cc7);
    }

    &:hover,
    &[href^='#'] {
        text-decoration: none;
    }
}
