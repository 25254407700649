@import '@assets/scss/utils.scss';

.travellers-selector {
    &__container {
        .ea-textfield {
            font-size: fs(s);
            text-align: center;
            height: sizer(5.5);
            width: sizer(7.5);
            padding: sizer(0.5) sizer(2);
            margin: 0 sizer(1.5);
            border-radius: sizer(1);
            border: rem(2px) solid $c-grey;
            background: $c-bright;
        }
    }
}
