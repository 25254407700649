$f-route: '../../fonts';

// Open Sans
@font-face {
    font-family: 'Open Sans';
    src: url('#{$f-route}/openSans/OpenSans-Regular.woff2') format('woff2'),
        url('#{$f-route}/openSans/OpenSans-Regular.woff') format('woff'),
        url('#{$f-route}/openSans/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$f-route}/openSans/OpenSans-SemiBold.woff2') format('woff2'),
        url('#{$f-route}/openSans/OpenSans-SemiBold.woff') format('woff'),
        url('#{$f-route}/openSans/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
// Daxline Pro
@font-face {
    font-family: 'Daxline Pro';
    src: url('#{$f-route}/daxlinePro/DaxlinePro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Daxline Pro';
    src: url('#{$f-route}/daxlinePro/DaxlinePro-Medium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Daxline Pro';
    src: url('#{$f-route}/daxlinePro/DaxlinePro-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Daxline Pro';
    src: url('#{$f-route}/daxlinePro/DaxlinePro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
