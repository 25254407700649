.ea-radio {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &__value {
        font-size: fs(s);
        padding-left: sizer(3.25);

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: sizer(1.5);
            transform: translate(-50%, -50%);

            @include circle(var(--circle-size));
        }

        &::before {
            --circle-size: #{sizer(2.5)};

            border: rem(2px) solid $c-brand-second;
        }

        &::after {
            --circle-size: #{sizer(1.5)};
            --bg-inner-circle: transparente;

            background: var(--bg-inner-circle);
            transition: background 0.3s ease;
        }
    }

    &__input {
        position: absolute;
        visibility: hidden;

        &:checked {
            + .ea-radio__value::after {
                --bg-inner-circle: #{$c-brand-second};
            }
        }
    }
}
