.ea-icon-component {
    --rich-color: #{$c-brand-third};
    --base-color: #{$c-brand-second};
    --grey-color: #{$c-grey-dark};
    --size: #{sizer(2)};

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @include square(var(--size));
    @include min-square(var(--size));

    svg {
        @include square(var(--size));
        @include min-square(var(--size));
    }

    &.size {
        &--xxs {
            --size: #{map_get($size-vars, 'xxs')};
        }

        &--xs {
            --size: #{map_get($size-vars, 'xs')};
        }

        &--s {
            --size: #{map_get($size-vars, 's')};
        }

        &--m {
            --size: #{map_get($size-vars, 'm')};
        }

        &--xm {
            --size: #{map_get($size-vars, 'xm')};
        }

        &--xxm {
            --size: #{map_get($size-vars, 'xxm')};
        }

        &--l {
            --size: #{map_get($size-vars, 'l')};
        }

        &--xl {
            --size: #{map_get($size-vars, 'xl')};
        }

        &--xxl {
            --size: #{map_get($size-vars, 'xxl')};
        }

        &--xxxl {
            --size: #{map_get($size-vars, 'xxxl')};
        }
    }

    &.uncolor {
        --rich-color: currentColor;
        --base-color: currentColor;
    }
}
