@import '@assets/scss/utils.scss';

// $$ vars

// Animations
$ea-private-transition-rhythm: 0.3s ease;

// Menu user
$ea-private-header-menu-width: sizer(28);

// btn menu user
$ea-private-header-btn-size: sizer(4);
$ea-private-header-btn-color-hover: $c-bright;
$ea-private-header-btn-bg-hover: $c-brand-quarter;
$ea-private-header-btn-bg-active: $c-brand-second;

// Avatar
$ea-private-header-avatar-size: sizer(5.25);
$ea-private-header-avatar-color: $c-bright;
$ea-private-header-avatar-bg: $c-brand-quarter;

// ## Data info user
.ea-user {
    display: flex;
    align-items: center;
    //justify-self: right;

    &__welcome {
        position: relative;
        display: flex;
        font-family: $ff-secondary;
        font-size: fs(m);
        font-weight: $fw-bold;
        padding-right: sizer(4);
        color: $c-brand-second;

        @include media-breakpoint(xs) {
            display: none;
        }

        &::after {
            content: ' ';
            position: absolute;
            top: sizer(1);
            right: sizer(2);
            width: rem(1px);
            height: sizer(1.5);
            border: 1px dashed $c-brand-second;

            @include media-breakpoint(sm) {
                display: none;
            }
        }
    }

    &__avatar {
        @include media-breakpoint(sm) {
            &::before {
                content: ' ';
                position: absolute;
                top: 50%;
                width: rem(2px);
                left: sizer(-2);
                height: sizer(2);
                background: $c-brand-second;
                transform: translateY(-50%);
            }
        }

        .ea-button-component {
            --icon-position: 0;
        }
    }

    &__btn {
        &.ea-button-component {
            --min-height: #{sizer(4.5)} !important;
            --padding-base: 0 !important;
            --bc-base: transparent !important;
            --c-base: #{$fc-negative} !important;
            --bg-base: #{$c-brand-quarter} !important;
            --br-radius: 100% !important;
            --ff-base: #{$ff-base} !important;

            min-width: var(--min-height);
            width: 2.5rem;
            height: 2.5rem;

            &:hover,
            &:focus {
                --bc-base: transparent;
                --c-base: #{$ea-private-header-btn-color-hover};
                --bg-base: #{$c-brand-quarter};
            }

            .ea-button__value {
                font-family: Open Sans, Arial, sans-serif;
                font-weight: 600;
                font-size: fs(m) !important;
            }

            &:hover {
                color: $ea-private-header-btn-color-hover;
                background: $ea-private-header-btn-bg-hover;
            }
        }

        &.intermediary {
            &.with-icon {
                .ea-icon-component {
                    --margin-left: 0;

                    @include square(sizer(2.5));
                }
            }
        }

        &.active {
            color: $ea-private-header-btn-color-hover;
            background: $ea-private-header-btn-bg-hover;
        }
    }

    &-data {
        &__name {
            --name-fs: #{fs(xs)};

            max-width: sizer(34);
            font-size: var(--name-fs);
            font-weight: $fw-bold;
            color: $c-brand-second;
        }

        &__email {
            --email-fs: #{fs(xs)};

            max-width: sizer(34);
            font-size: var(--email-fs);
        }

        &__logout {
            display: flex;
            justify-content: center;
            font-weight: $fw-semibold;
            width: 100%;

            .ea-button-component {
                --fw-base: #{$fw-semibold};
            }
        }
    }

    // ## Dropdown header whit user info
    .ea-user-menu {
        --list-position: #{sizer(6)};
        --list-padding: #{sizer(5) sizer(3) sizer(2) sizer(3)};
        --list-max-width: #{sizer(40)};
        --list-border-radius: #{$br-big};
        --list-bg: #{$c-bright};
        --list-z-index: #{z(above)};
        --item-direction: column;
        --item-fs: #{fs(s)};
        --item-padding: #{sizer(1) 0};

        position: relative !important;

        &__list {
            --list-transform: 0;

            position: absolute;
            top: sizer(3);
            right: 0;
            display: flex;
            flex-direction: column;
            width: var(--list-max-width);
            min-width: var(--list-max-width);
            padding: var(--list-padding);
            border-radius: var(--list-border-radius);
            transform: var(--list-transform);
            background: var(--list-bg);
            z-index: var(--list-z-index);

            @include shadow(dropdown);
        }

        &__item {
            overflow: hidden;
            display: flex;
            flex: 1 0 100%;
            flex-wrap: nowrap;
            flex-direction: var(--item-direction);
            font-size: var(--item-fs);

            &:not(:last-child) {
                padding-bottom: sizer(2);
            }

            &:last-child {
                padding-bottom: sizer(1);
            }

            &.user-data {
                --item-direction: column;
            }
        }

        &__data {
            font-weight: $fw-semibold;
            text-decoration: none;
            width: 100%;
        }

        &__link {
            display: flex;
            font-family: $ff-secondary;
            text-decoration: none;
            cursor: pointer;
        }
    }
}

.list--container {
    position: absolute;
    border-radius: 5px;
    display: block;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    background: #fff;
    z-index: 10;
    font-weight: bold;
}

.language-country {
    font-weight: bold;
}

.language-element {
    cursor: pointer;
    &:hover {
        opacity: 0.8;
        > div {
            background: #f5f5f5;
        }
    }
    div {
        span {
            &:first-child {
                width: 35px;
                height: 35px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 20px;
                background-size: contain;
            }
        }
    }
    #ES {
        span {
            &:first-child {
                background-image: url('../../../../assets/images/es.svg');
            }
        }
    }
    #EN {
        span {
            &:first-child {
                background-image: url('../../../../assets/images/en.svg');
            }
        }
    }
    #DE {
        span {
            &:first-child {
                background-image: url('../../../../assets/images/de.svg');
            }
        }
    }
    #FR {
        span {
            &:first-child {
                background-image: url('../../../../assets/images/fr.svg');
            }
        }
    }
    #IT {
        span {
            &:first-child {
                background-image: url('../../../../assets/images/it.svg');
            }
        }
    }
    #pt {
        span {
            &:first-child {
                background-image: url('../../../../assets/images/pt.svg');
            }
        }
    }
    #EU {
        span {
            &:first-child {
                background-image: url('../../../../assets/images/eu.svg');
            }
        }
    }
    > div {
        padding: 7px;
        border-radius: 5px;
        transition: 0.2s all ease-in-out;
    }
}
