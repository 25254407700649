@import '@assets/scss/utils.scss';

.loader {
    --width: #{sizer(7)};
    --height: --width;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    &--full-screen {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: z('menu');
        background: $c-grey-lightest;

        .circular {
            position: relative;
        }
    }

    .circular {
        animation: rotate 2s linear infinite;
        height: var(--height);
        width: var(--width);
    }

    .path {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        stroke: $c-brand-second;
        animation: dash 1.5s ease-in-out infinite;
        stroke-linecap: round;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}
