@each $name, $value in $size-vars {
    .fs-#{$name} {
        font-size: rem($value);
    }
}

@each $name, $value in $fc-collection {
    .fc-#{$name} {
        color: $value;
    }
}

@each $name, $value in $bg-collection {
    .bg-#{$name} {
        background-color: $value;
    }
}

@for $i from 0 through 20 {
    $count: ($i * 5) + 5;

    .width-#{$count} {
        width: percentage($count/100);
    }
}

//---------------
// ## display
//---------------

.block,
.show,
.mostrar,
.visible {
    display: block;
}

.inline {
    display: inline-block;
}

.none,
.hide,
.ocultar,
.invisible {
    display: none !important;
}

// $$ Flex
.flex {
    display: flex !important;

    &--no {
        flex: none;
    }

    &--row {
        display: flex;
        flex-direction: row;
        // $$ Reverse
        &.reverse,
        &.rev,
        &--reverse,
        &--rev {
            flex-direction: row-reverse;
        }
    }

    &--column {
        display: flex;
        flex-direction: column;
        // $$ Reverse
        &.reverse,
        &.rev,
        &--reverse,
        &--rev {
            flex-direction: column-reverse;
        }
    }

    &--wrap {
        flex-wrap: wrap;
    }

    &--nowrap {
        flex-wrap: nowrap;
    }

    // $$ Grow & shrink
    &--1-0 {
        flex-grow: 1;
        flex-shrink: 0;
    }

    &--0-1 {
        flex-grow: 0;
        flex-shrink: 1;
    }

    &--1-1 {
        flex-grow: 1;
        flex-shrink: 1;
    }

    // $$ Reverse
    &--rev,
    &--reverse,
    &.rev,
    &.reverse {
        @extend .flex;

        flex-direction: row-reverse;
    }

    // $$ Item
    &-item {
        &--auto,
        &--a {
            display: flex;
            flex: 0 1 auto;
        }

        &--100 {
            display: flex;
            flex: 1 0 auto;
        }

        &--top,
        &--t {
            margin-bottom: auto;
        }

        &--right,
        &--r {
            margin-left: auto;
        }

        &--bottom {
            margin-top: auto;
        }

        &--left,
        &--l {
            margin-right: auto;
        }

        // $$ Reverse
        &--rev,
        &--reverse,
        &.rev,
        &.reverse {
            @extend .flex;

            flex-direction: row-reverse;
        }
    }
    // $$ Horizontal
    &.left {
        justify-content: flex-start;

        &--auto {
            margin-left: auto;
        }
    }

    &.center {
        justify-content: center;
    }

    &.right {
        justify-content: flex-end;

        &--auto {
            margin-right: auto;
        }
    }

    // $$ Vertical
    &.top {
        align-items: flex-end;

        &--auto {
            margin-top: auto;
        }
    }

    &.middle {
        align-items: center;
    }

    &.bottom {
        align-items: flex-start;

        &--auto {
            margin-bottom: auto;
        }
    }

    &.stretch {
        align-items: stretch;
    }

    &.baseline {
        align-items: baseline;
    }

    // $$ distribution
    &.around {
        justify-content: space-around;
    }

    &.between {
        justify-content: space-between;
    }
}

// $$ States
.has,
.is {
    &--hide {
        display: none;
    }

    &--show {
        display: block;
    }

    &--flex {
        display: flex;
    }
}

.test-ui {
    @include test-ui();
}

.paragraph {
    font-size: sizer(1.75);
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

// $$ BTN Ripple effect
// $$ Ripple animation
@keyframes animation-effect--ripple {
    0% {
        opacity: 0.8;
        transform: scale(0, 0);
    }

    100% {
        opacity: 0;
        transform: scale(20, 20);
    }
}

.effect--ripple {
    position: relative;
    overflow: hidden;

    &::after {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0;
        border-radius: 16rem;
        background: $c-grey-light;
        transform: scale (1, 1) translate(-50%, -50%);
        transform-origin: 50% 50%;
        pointer-events: none;

        @include square(sizer(1));
    }

    &:focus:not(:active)::after {
        animation: animation-effect--ripple;
        animation-duration: 0.75s;
        transition-timing-function: ease-out;
    }
}
