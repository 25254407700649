//---------------
// ## Mixins
//---------------
@mixin media-breakpoint($name) {
    @if map-has-key($breakpoints, $name) {
        @media (max-width: em(map-get($breakpoints, $name))) {
            @content;
        }
    } @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoints}`. "+"Please make sure it is defined in `$breakpoints` map.";
    }
}

// $$ Clearfix
@mixin clearfix() {
    zoom: 1;

    &::before {
        content: '';
        display: table;
    }

    &::after {
        content: ' ';
        display: table;
        visibility: hidden;
        clear: both;
        font-size: 0;
        height: 0;
    }
}
// Usage:
// @include clearfix;

//----------------
// ## Size
//----------------

// $$ size - tamaño ancho y alto
@mixin size($w: null, $h: null) {
    width: $w;
    height: $h;
}
// Usage:
// @include size(4px, 2px);

// $$ min-size
@mixin min-size($mw: null, $mh: null) {
    min-width: $mw;
    min-height: $mh;
}
// Usage:
// @include min-size(4px, 2px);

//----------------
// ## Shape
//----------------

// $$ square
@mixin square($s: null) {
    @include size($w: $s, $h: $s);
}
// Usage:
// @include square(1rem);

@mixin min-square($s: null) {
    @include min-size($mw: $s, $mh: $s);
}
// Usage:
// @include min-square(3rem);

// $$ circle
@mixin circle($size: null) {
    border-radius: 100%;

    @include square($size);
}
// Usage:
// @include circle(3rem);

//----------------
// ## Shadows
//----------------

@mixin shadow($ShadowType: null) {
    @if $ShadowType == dropdown {
        -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
    }

    @if $ShadowType == container {
        -webkit-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.12);
    }

    @if $ShadowType == defaultcard {
        -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
    }

    @if $ShadowType == defaultdcardhover {
        -webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
    }

    @if $ShadowType == pale {
        -webkit-box-shadow: 0 0.25rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 0.25rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
        box-shadow: 0 0.25rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
    }

    @if $ShadowType == soft {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
            0 4px 4px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);
    }

    @if $ShadowType == medium {
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.3);
    }

    @if $ShadowType == hard {
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.5);
    }
}
// Usage:
// @include shadow(soft);

//----------------
// ## From
//----------------

// $$ Placeholder
@mixin color-placeholder($color) {
    &::-webkit-input-placeholder {
        color: $color;
    }

    &:-ms-input-placeholder {
        color: $color;
    }

    &::-ms-input-placeholder {
        color: $color;
    }

    &::placeholder {
        color: $color;
    }
}

//----------------
// ## Animation
//----------------

@mixin animation(
    $name,
    $duration: null,
    $delay: null,
    $iteration: 1,
    $timing: ease,
    $fillmode: forwards
) {
    animation-name: $name;
    animation-duration: $duration;
    animation-iteration-count: $iteration;
    animation-delay: $delay;
    animation-timing-function: $timing;
    animation-fill-mode: $fillmode;
}

@mixin animation--fade-in {
    @keyframes fadeIn {
        100% {
            opacity: 1;
        }
    }
}

//----------------
// ## Test
//----------------

@mixin test-ui {
    border: 3px dashed deeppink !important;
    background: greenyellow !important;
}
