.ea-selector-component {
    padding: 0;
    width: 100%;
    display: flex;

    + .ea-button-component {
        margin-left: sizer(2);
    }

    svg {
        --fill-svg: #{$fc-action};

        fill: var(--fill-svg);
    }

    &.ea-validate-msg {
        &--error {
            .ea-selector {
                &__control {
                    border-color: $fc-error !important;
                    color: $fc-error;

                    svg {
                        fill: $fc-error;
                    }

                    @include color-placeholder($fc-error);
                }
            }
        }
    }

    .ea-selector {
        border-color: $bc-action;

        &__control {
            padding: 0;
            display: flex;

            @extend %form-base;

            &--is-focused {
                box-shadow: none;
                border-color: $bc-action !important;
            }

            &:hover {
                border-color: $bc-action;

                input {
                    color: $fc-action;

                    @include color-placeholder($fc-action);
                }
            }
        }

        &__indicator-separator {
            display: none;
        }

        &__menu {
            border-radius: sizer(0.5);
            z-index: z(selector-menu);

            @include shadow(soft);
        }

        &__option {
            align-items: center;
            color: $fc-base;
            display: inline-flex;
            height: 100%;

            &:hover {
                cursor: pointer;
            }

            &--is-focused,
            &:focus,
            &:active {
                background-color: $bg-option-focused;
            }

            &--is-selected {
                background-color: $bg-option-selected;
                color: $fc-option-selected;
            }

            &--is-disabled {
                background-color: $bc-disabled;
                color: $fc-disabled;
                cursor: not-allowed !important;
            }
        }

        &__single-value {
            width: 100%;
            padding-right: sizer(1);
        }

        &__menu-list {
            padding-bottom: sizer(1);
            padding-top: sizer(1);
        }
    }
}
