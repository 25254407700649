.ea-button-component {
    // $$ Vars
    --min-height: #{sizer(5.5)};
    --padding-base: #{sizer(1) sizer(3)};
    --ff-base: #{$ff-secondary};
    --fs-base: #{fs(s)};
    --fw-base: #{$fw-bold};
    --c-base: #{$fc-negative};
    --bg-base: #{$c-brand-second};
    --bc-base: #{$c-brand-second};
    --br-radius: #{$br-big};

    // $$ Icon
    --icon-position: 0 0 0 #{sizer(1)};

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: rem(2px) solid var(--bc-base);
    min-height: var(--min-height);
    padding: var(--padding-base);
    font-family: var(--ff-base);
    font-size: var(--fs-base);
    font-weight: var(--fw-base);
    border-radius: var(--br-radius);
    color: var(--c-base);
    background: var(--bg-base);
    transition: all 0.3s ease;

    // $$ Kind
    &.ea-button {
        &--primary {
            --min-height: #{sizer(5.5)};
            --padding-base: #{sizer(1) sizer(3)};
            --fs-base: #{fs(s)};
            --c-base: #{$fc-negative};
            --bg-base: #{$c-brand-second};
            --bc-base: #{$c-brand-second};
            --br-radius: #{sizer(1)};

            &:hover,
            &:focus {
                --c-base: #{$fc-negative};
                --bc-base: #{$c-brand-second-hover};
                --bg-base: #{$c-brand-second-hover};
            }

            &:active {
                --c-base: #{$fc-negative};
                --bc-base: #{$c-brand-second-focus};
                --bg-base: #{$c-brand-second-focus};
            }
        }

        &--secondary {
            --c-base: #{$fc-negative};
            --bg-base: #{$c-brand-third};
            --bc-base: #{$c-brand-third};

            &:hover,
            &:focus {
                --c-base: #{$fc-negative};
                --bc-base: #{$c-brand-third-hover};
                --bg-base: #{$c-brand-third-hover};
            }

            &:active {
                --c-base: #{$fc-negative};
                --bc-base: #{$c-brand-third-focus};
                --bg-base: #{$c-brand-third-focus};
            }
        }

        &--tertiary {
            --c-base: #{$c-brand-second};
            --bg-base: #{$c-bright};
            --bc-base: #{$c-grey};

            &:hover,
            &:focus {
                --c-base: #{$c-brand-second};
                --bc-base: #{$c-brand-second};
                --bg-base: #{$c-bright};
            }

            &:active {
                --bg-base: #{$c-grey};
            }
        }

        &--quaternary {
            --c-base: #{$c-brand-second};
            --bg-base: #{$c-bright};
            --bc-base: #{$c-bright};

            &:hover,
            &:focus {
                --c-base: #{$c-bright};
                --bc-base: #{$c-brand-second};
                --bg-base: #{$c-brand-second};
            }

            &:active {
                --bg-base: #{$c-grey};
            }
        }

        &--quinary {
            --c-base: #{$c-brand-second};
            --bg-base: #{$c-grey-light};
            --bc-base: #{$c-grey-light};

            &:hover,
            &:focus {
                --c-base: #{$c-bright};
                --bc-base: #{$c-brand-second};
                --bg-base: #{$c-brand-second};
            }

            &:active {
                --bg-base: #{$c-grey-light};
            }

            &:disabled {
                --c-base: #{$c-grey-disabled};
                --bc-base: #{$c-grey-border-disabled};
                --bg-base: transparent;
            }
        }

        &--ghost {
            --c-base: #{$c-brand-second};
            --bg-base: transparent;
            --bc-base: transparent;

            &:hover,
            &:focus {
                --bc-base: #{$c-bright};
                --bg-base: #{$c-bright};

                @include shadow(dropdown);
            }

            &:active {
                --bg-base: #{$c-grey-light};
            }

            &:disabled {
                --c-base: #{$c-grey-disabled};
                --bc-base: #{$c-grey-border-disabled};
                --bg-base: transparent;
            }
        }
    }

    &.ea-button {
        // $$ ea-round-button
        &--round {
            --padding-base: 0;
            --br-radius: 100%;
            --icon-position: 0;
            --min-height: #{sizer(5.5)};

            position: relative;
            flex: 1 0 var(--min-height);
            min-height: var(--min-height);
            min-width: var(--min-height);
            max-height: var(--min-height);
            max-width: var(--min-height);
            border-width: 2px;
            color: var(--c-base);
            background: var(--bg-base);

            .ea-button__value {
                overflow: hidden;
                position: absolute;
                visibility: hidden;

                @include square(0);
            }
        }
    }

    &.size {
        &--l {
            --min-height: #{sizer(5.5)};
        }

        &--m {
            --min-height: #{sizer(4.5)};
        }

        &--s {
            --min-height: #{sizer(3.5)};
        }

        &--xs {
            --min-height: #{sizer(2.5)};
        }

        &--xxs {
            --min-height: #{sizer(2)};
        }
    }

    // $$ Icon
    .ea-button__value {
        + .ea-icon-component {
            margin: var(--icon-position);
        }
    }

    &.icon--left {
        --icon-position: 0 #{sizer(1)} 0 0;

        flex-direction: row-reverse;
    }

    // disable
    &:disabled {
        --c-base: #{$c-grey-font-disabled};
        --bc-base: #{$c-grey-border-disabled};
        --bg-base: #{$c-grey-border-disabled};

        pointer-events: none;
    }
}
