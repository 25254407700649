//---------------
// ## DESKTOP MEASURE
//---------------
// January 2018:
// 1920x1080 - 18%
// 1366x768	- 34%
// 1280x1024 - 4%
// 1280x800 - 3%
// 1024x768	- 2%
// Lower - 6.1%

// More information:
// http://gs.statcounter.com/
// https://www.w3schools.com/browsers/browsers_display.asp
// http://screensiz.es

//---------------
// ## Root unit
//---------------
$unit: 16;
$unit-app: $unit * 1px;
$gutter-width: $unit-app/2;

// $$ Desktop screen resolution
$screen-full: 100%;
$screen-standard: 1440; //save measure
$screen-s: 960px;
$screen-m: 1280px;
$screen-l: 1366px;

//---------------
// ## Grid and Responsive
//---------------
$grid-cols: 12;
$desktop: 1440px;
$desktop-small: 1280px;
$desktop-100: 100%;
$tablet-landscape: 960px;
$tablet-portrait: 768px;
$mobile-landscape: 720px;
$mobile-portrait: 650px;
$mobile-portrait-small: 320px;

//---------------
// ## Grid system
//---------------
.wrapper {
    display: block;
    width: $desktop-small;
    padding: 0;
    margin: 0 auto;

    &.relative {
        position: relative;
    }

    &--big {
        width: sizer(182);
    }
}

// $$ Row
.grid-row,
.g-row {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 auto;
    align-items: flex-start;
    width: 100%;
    // $$ Reverse
    &.reverse,
    &.rev,
    &--reverse,
    &--rev {
        flex-direction: row-reverse;

        @extend .grid-row;
    }

    &--column {
        flex-direction: column;

        @extend .grid-row;

        .grid-desktop--12,
        .g-desktop--12 {
            width: 100%;
            flex-basis: initial;
        }

        // $$ Reverse
        &.reverse,
        &.rev,
        &--reverse,
        &--rev {
            flex-direction: row-reverse;

            @extend .grid-row;
        }
    }

    &--gutter {
        @extend .grid-row;

        & > [class*='grid-'],
        & > [class*='g-'] {
            padding-left: $gutter-width;
            padding-right: $gutter-width;

            &:first-child {
                padding-left: 0 !important;
            }

            &:last-child {
                padding-right: 0;
            }

            @include ie-media {
                padding-left: 0;
                padding-right: 0;
            }
        }

        & > [class*='desktop--6'] {
            &:first-child {
                padding-right: $gutter-width;
            }

            &:last-child {
                padding-left: $gutter-width;
            }
        }
        // $$ Reverse
        &.reverse,
        &.rev,
        &--reverse,
        &--rev {
            flex-direction: row-reverse;

            @extend .grid-row;
        }

        @media only screen and (max-width: $tablet-landscape) {
            & > .grid-tablet--12,
            .g-tablet--12,
            .grid-tablet-portrait--12,
            .g-tablet-portrait--12,
            .grid-mobile--12,
            .g-mobile--12,
            .grid-desktop-small--12,
            .g-desktop-small--12,
            .grid-desktop--12,
            .g-desktop--12 {
                padding-left: 0;
                padding-right: 0;
            }
        }

        @media only screen and (max-width: $desktop-small) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.wrap {
        flex-wrap: wrap;

        &--no {
            flex-wrap: nowrap;
        }

        // $$ Reverse
        &.reverse,
        &.rev,
        &--reverse,
        &--rev {
            flex-wrap: wrap-reverse;
        }
    }

    & > [class*='grid-'],
    & > [class*='g-'] {
        display: flex;
        flex-wrap: wrap;
    }
}

// $$ Columns
@for $i from 1 through $grid-cols {
    .grid-desktop--#{$i},
    .g-desktop--#{$i} {
        flex-basis: ((100 / $grid-cols) * $i) * 1%;
        width: ((100 / $grid-cols) * $i) * 1%;
    }
}

@for $i from 1 through $grid-cols {
    .grid-center--#{$i},
    .g-center--#{$i} {
        flex-basis: ((100 / $grid-cols) * $i) * 1%;
        width: ((100 / $grid-cols) * $i) * 1%;
        margin: 0 auto;
    }
}

@for $i from 1 through ($grid-cols)-1 {
    .grid-offset--#{($i)},
    .g-offset--#{($i)} {
        margin-left: ((100 / $grid-cols) * $i) * 1%;
    }
}

// $$  Fifths / 5 columns
.grid-fifth,
.g-fifth {
    flex-basis: 20%;
    width: 20%;

    &--center {
        flex-basis: 20%;
        width: 20%;
        margin: 0 auto;
    }
}

@for $i from 1 through 4 {
    .grid-fifth-offset--#{($i)},
    .g-fifth-offset--#{($i)} {
        margin-left: 20% * ($i);
    }
}

// $$ special behaviors
.grid-100,
.g-100 {
    flex: 1 0 auto;
}

.grid-auto,
.g-auto {
    flex: 0 1 auto;
}

// $$ Direction, position and distribution
[class*='grid-'],
[class*='g-'] {
    //$$ Direction
    &.vertical,
    &.column {
        flex-direction: column;
        height: max-content;
        // $$ Reverse
        &.reverse,
        &.rev,
        &--reverse,
        &--rev {
            flex-direction: column-reverse;
        }

        .grid-desktop--12,
        .g-desktop--12 {
            flex-basis: initial;
        }
    }

    &.horizontal {
        flex-direction: row;
        // $$ Reverse
        &.reverse,
        &.rev,
        &--reverse,
        &--rev {
            flex-direction: row-reverse;
        }
    }

    // $$ Horizontal
    &.left {
        justify-content: flex-start;

        &--auto {
            margin-left: auto;
        }
    }

    &.center {
        justify-content: center;
    }

    &.right {
        justify-content: flex-end;

        &--auto {
            margin-right: auto;
        }
    }

    // $$ Vertical
    &.top {
        align-items: flex-end;

        &--auto {
            margin-top: auto;
        }
    }

    &.middle {
        align-items: center;
    }

    &.bottom {
        align-items: flex-end;

        &--auto {
            margin-bottom: auto;
        }
    }

    &.stretch {
        align-items: stretch;
    }

    &.baseline {
        align-items: baseline;
    }

    //$$ distribution
    &.around {
        justify-content: space-around;
    }

    &.between {
        justify-content: space-between;
    }
}

// $$ show & hide by devices
.only {
    &--desktop {
        display: block;
    }

    &--tablet,
    &--mobile {
        display: none;
    }
}

.not {
    &--desktop {
        display: none;
    }
}

//---------------
// ## Media queries
//---------------
@media only screen and (max-width: $desktop - 1) {
    .wrapper {
        width: 100%;
    }

    @for $i from 1 through $grid-cols {
        .grid-desktop-small--#{$i},
        .g-desktop-small--#{$i} {
            flex-basis: ((100 / $grid-cols) * $i) * 1%;
            width: ((100 / $grid-cols) * $i) * 1%;
        }

        .grid-tablet--6 {
            padding-left: $gutter-width;
            padding-right: $gutter-width;

            &:nth-child(even) {
                padding-right: 0;
            }

            &:nth-child(odd) {
                padding-left: 0;
            }
        }
    }
}

@media only screen and (max-width: $tablet-landscape + 1) {
    .wrapper {
        max-width: $tablet-landscape;
    }

    @for $i from 1 through $grid-cols {
        .grid-tablet--#{$i},
        .g-tablet--#{$i} {
            flex-basis: ((100 / $grid-cols) * $i) * 1%;
            width: ((100 / $grid-cols) * $i) * 1%;
        }
    }

    .grid-row--gutter {
        .grid-tablet--6 {
            padding-left: $gutter-width;
            padding-right: $gutter-width;

            &:nth-child(even) {
                padding-right: 0;
            }

            &:nth-child(odd) {
                padding-left: 0;
            }
        }
    }
}

@media only screen and (max-width: $tablet-portrait) {
    .wrapper {
        max-width: $tablet-portrait;
    }

    @for $i from 1 through $grid-cols {
        .grid-tablet-portrait--#{$i},
        .g-tablet-portrait--#{$i} {
            flex-basis: ((100 / $grid-cols) * $i) * 1%;
            width: ((100 / $grid-cols) * $i) * 1%;
        }
    }
}

@media only screen and (max-width: $mobile-landscape) {
    .wrapper {
        max-width: $mobile-landscape;
    }

    @for $i from 1 through $grid-cols {
        .grid-mobile--#{$i},
        .g-mobile--#{$i} {
            flex-basis: ((100 / $grid-cols) * $i) * 1%;
            width: ((100 / $grid-cols) * $i) * 1%;
        }
    }

    .grid-tablet--6 {
        &:nth-child(even) {
            padding-right: 0.25rem;
        }

        &:nth-child(odd) {
            padding-left: 0.25rem;
        }
    }
}

@media only screen and (min-width: 10px) and (max-width: $mobile-portrait) {
    .wrapper {
        max-width: 100% !important;
    }

    .grid-row > [class*='grid-'],
    .g-row > [class*='g-'] {
        flex-basis: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .grid-row--gutter > [class*='grid-'],
    .g-row--gutter > [class*='g-'] {
        width: 100%;
        padding-left: 0 !important;
    }

    .grid-tablet--6 {
        padding-left: $gutter-width;
        padding-right: $gutter-width;

        &:nth-child(even),
        &:nth-child(odd) {
            padding: 0;
        }
    }

    @for $i from 1 through ($grid-cols) {
        .mobile-grid--#{$i},
        .mobile-g--#{$i} {
            flex-basis: ((100 / $grid-cols) * $i) * 1% !important;
        }
    }
}

@media only screen and (max-width: $desktop - 1) {
    .not {
        &--desktop-small {
            display: none !important;
        }
    }
}

@media only screen and (min-width: $tablet-portrait - 1) and (max-width: $tablet-landscape) {
    .only {
        &--desktop {
            display: none !important;
        }

        &--tablet {
            display: block !important;
        }
    }

    .not {
        &--tablet {
            display: none !important;
        }

        &--desktop {
            display: block !important;
        }
    }
}

@media only screen and (max-width: $tablet-portrait - 1) {
    .only {
        &--mobile {
            display: block;
        }

        &--desktop {
            display: none;
        }

        &--tablet {
            display: none !important;
        }
    }

    .not {
        &--mobile {
            display: none !important;
        }

        &--desktop {
            display: block !important;
        }
    }
}
