@import '@assets/scss/utils.scss';

$ea-private-header-height: sizer(32);

main {
    width: 100%;
    min-height: calc(100vh - #{$footer-height});
    padding: $ea-private-header-height 0 sizer(4) 0;
    margin: 0 auto;

    @include media-breakpoint(sm) {
        padding: sizer(11) sizer(2) 0;
    }
}

.template {
    &__sumary {
        --template-main: 'main aside';

        display: grid;
        grid-template-areas: var(--template-main);
        grid-template-columns: 1fr sizer(52);
        grid-column-gap: sizer(3);

        @include media-breakpoint(sm) {
            --template-main: 'main' 'aside';

            grid-template-columns: 1fr;
            grid-row-gap: sizer(3);
        }
    }

    &__form {
        $grid-cols: 6;

        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-auto-flow: row;
        grid-gap: sizer(3.5) sizer(1.5);

        @for $i from 1 through $grid-cols {
            .col--#{$i} {
                -ms-grid-column-span: $i;
                grid-column-end: span #{$i};
            }
        }

        + .template__form {
            padding-top: sizer(3.5);
        }

        @include media-breakpoint(sm) {
            display: flex;
            flex-direction: column;

            .ea-textfield-component {
                padding-bottom: sizer(4);
            }
        }
    }
}
