@import '@assets/scss/utils.scss';

// ## Vars

.ea-recovery-policy-notification-component {
    --msg-border-radius: #{sizer(1)};
    --msg-padding: #{sizer(0.5) sizer(1)};
    --msg-font-size: #{fs(xs)};

    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--msg-padding);
    border-radius: var(--msg-border-radius);
    border: var(--border-base);
    background: var(--bg-base);

    @include media-breakpoint(sm) {
        --msg-border-radius: 0;
        --msg-padding: #{sizer(1.5) sizer(1)};

        position: fixed;
        top: sizer(8.5);
        left: 0;
        right: 0;
        width: 100%;
        z-index: z(default-content);
    }

    .ea-recovery-policy-notification {
        //Prefix
        &__msg {
            display: flex;
            flex: 1 1 auto;
            justify-content: center;
            font-size: var(--msg-font-size, 1rem);
            color: var(--color-base, #{$c-grey});
        }

        &__btn {
            display: flex;
            flex: 1 0 auto;
            border-color: transparent;
            background: transparent !important;
            transform: scale(0.85);
        }
    }
    // $$ Kind
    &.default {
        --bg-base: #{$c-bright};
        --border-base: 1px solid #{$c-grey};
    }

    &.error {
        --color-base: #{$c-bright};
        --bg-base: #{$c-red};
        --border-base: 1px solid #{$c-red};
    }

    &.success {
        --color-base: #{$c-bright};
        --bg-base: #{$c-brand-quarter};
        --border-base: 1px solid #{$c-brand-quarter};
    }
}
