@import '@assets/scss/utils.scss';

$h-nav-bar: sizer(8);
$ts-launcher-title: 0 rem(6px) rem(12px) rgba(0, 0, 0, 0.12);

.ea-launcher-container {
    position: relative;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    transition: all 0.5s ease-in-out;
    z-index: z(default);

    &:not(.is-active) {
        .ea-launcher__controls,
        .ea-launcher__control-label {
            cursor: pointer;
        }
    }

    &__title {
        position: relative;
        padding: sizer(4) sizer(2.5) sizer(2.5) sizer(4);
        font-weight: $fw-extrabold;
        color: $c-title-launcher;

        @include media-breakpoint(xs) {
            font-size: fs(xm);
            margin: sizer(3) 0 sizer(2);
            padding: 0;
            color: $fc-quarter;
            text-align: center;
        }

        &::before {
            content: '';
            border-top: sizer(1.75) solid $c-title-launcher;
            border-left: sizer(1.75) solid $c-title-launcher;
            position: absolute;
            top: 0;
            left: 0;

            @include square(sizer(3.5));

            @include media-breakpoint(xs) {
                display: none;
            }
        }
    }

    .ea-country-selector {
        display: flex;
        flex: 1 0 100%;
        align-items: center;
        width: 100%;

        @include media-breakpoint(xs) {
            width: 100%;
        }

        > div {
            width: 100%;
        }

        .ea-select-multiple__input-container {
            position: relative;
            display: flex;
            flex: 1 0 100%;
            align-items: center;
            margin: 0 auto;
            width: 80%;
            max-width: sizer(47.5);
        }

        .ea-select-multiple__selected-options {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 80%;
            margin: 0 auto;
            padding-top: 0;

            .selected-option__item {
                margin-top: sizer(3);
                margin-left: 0;
                margin-right: sizer(1);
            }
        }

        //Only for Launcher datepicker
        .react-datepicker {
            display: flex;
            justify-content: space-around;
            padding: sizer(6) sizer(15) 0;
            min-height: sizer(38);
            background: none;
            box-shadow: none;

            &__year-dropdown-container {
                position: absolute;
                top: sizer(0.5);
                left: 0;
                right: 0;
                width: 100%;
                min-width: sizer(10);
            }

            &__navigation {
                &--next,
                &--previous {
                    top: 50%;

                    @include circle(sizer(3));
                }
            }

            &__header {
                position: static;

                &__dropdown--select {
                    padding: sizer(2) 0 0 0;
                }
            }
        }

        .travellers-selector {
            &__wrapper {
                display: flex;
                padding-top: sizer(3);
                width: auto !important;

                &:first-child {
                    margin-left: auto;
                }

                &:last-child {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            &__container {
                display: flex;
                padding: 0 sizer(4);

                .ea-textfield {
                    padding: 0;
                }
            }

            &__title {
                color: $c-brand-second;
                font-size: sizer(3);
                font-weight: $fw-extrabold;
            }

            &__description {
                color: $c-grey-darkest;
                font-size: fs(s);
            }
        }
    }

    &__products {
        .ea-launcher-container__title {
            text-align: center;
            color: $c-brand-second;
            font-size: fs(xxm);

            &::before {
                border: 0;
            }
        }
    }

    // $$ States
    &.is-active {
        flex-grow: 0;

        @include media-breakpoint(xs) {
            z-index: z(modal-content);
        }

        .ea-launcher {
            padding-bottom: 0;
        }

        .ea-launcher-panel-wrapper {
            overflow: visible;
            min-height: sizer(28);
            padding: sizer(9) sizer(1) sizer(4) sizer(1);

            @include media-breakpoint(xs) {
                transform: translateY(0);
            }
        }

        .ea-launcher-container__title {
            text-shadow: $ts-launcher-title;
        }
    }
}

.ea-launcher {
    position: relative;
    max-width: map-get($breakpoints, lg);
    padding-bottom: sizer(4);

    .is-notification-active {
        padding-bottom: sizer(8);
    }

    &__container {
        position: relative;
        display: flex;
        justify-content: center;
    }

    &__notification {
        position: absolute;
        bottom: sizer(2);
        width: calc(100% - #{sizer(4)});
        z-index: z(default-content);
    }

    &__controls {
        position: relative;
        display: flex;
        justify-content: center;
        align-content: center;
        padding: sizer(3) sizer(4);
        border-radius: $br-xbig;
        background: $bg-secondary;
        box-shadow: $bx-dropdowns;
        z-index: z(default-content);
        width: 100%;

        @include media-breakpoint(xs) {
            flex-wrap: wrap;
            padding: sizer(2.5) sizer(2);
        }
    }

    &__control {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: sizer(1) sizer(2);
        width: sizer(24);
        height: sizer(12);
        border-right: 2px solid $bc-divider;

        @include media-breakpoint(xs) {
            width: 50%;
            height: auto;
            padding: 0 sizer(1) sizer(2);
            margin-bottom: sizer(2);
            border-right: 0;
            border-bottom: rem(2px) solid $bc-divider;
        }

        &::after {
            content: '';
            width: 0;
            height: 0;
            border-color: $bg-secondary transparent transparent;
            border-style: solid;
            border-width: sizer(3) sizer(3) 0;
            position: absolute;
            bottom: sizer(-6);
            transition: all 0.5s ease-in 0.5s;
            transform: scale(0);
            transform-origin: top;

            @include media-breakpoint(xs) {
                display: none;
            }
        }

        &-label {
            font-family: $ff-secondary;
            color: $fc-heading-secondary;
            font-weight: $fw-extrabold;
            padding-bottom: sizer(1);
        }

        &.is-active {
            &::after {
                transform: scale(1);
            }
        }

        &.is-enabled {
            &:hover {
                * {
                    cursor: pointer;
                }
            }
        }

        &.is-active,
        &.is-enabled {
            .ea-launcher__control-label {
                color: $fc-heading;
            }
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            align-items: center;
            justify-content: center;
            border: 0;
            padding: 0;
            width: auto;
            padding-left: sizer(4);

            @include media-breakpoint(xs) {
                margin-bottom: 0;
                padding-left: 0;

                .ea-button-component {
                    width: 100%;
                }
            }
        }

        &:first-child,
        &:last-child {
            @include media-breakpoint(xs) {
                width: 100%;
            }
        }
    }

    form {
        @include media-breakpoint(xs) {
            width: 100%;
        }
    }

    &__info {
        display: flex;
        align-items: center;
        height: 100%;

        &.is-filled {
            .ea-icon-component,
            .ea-launcher__info-text {
                color: $fc-heading;
            }
        }

        .ea-icon-component {
            color: $fc-heading-secondary;
        }

        &-text {
            color: $fc-heading-secondary;
            line-height: $lh-base;
            padding-left: sizer(1.5);

            @include media-breakpoint(xs) {
                font-size: fs(xs);
            }

            &.is-visited {
                color: $fc-heading;
            }
        }
    }

    &__data {
        display: none;
        padding: sizer(5.5) 0 sizer(4.5) 0;
        justify-content: center;
        align-items: center;
        position: relative;
        top: sizer(-1);
    }

    &__option {
        display: none;
        justify-content: space-around;
        width: 100%;
    }
}

.ea-launcher-panel-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 0;
    margin-top: sizer(-4);
    border-radius: $br-xbig;
    background: $bg-secondary;
    box-shadow: $bx-dropdowns;
    transition: all 0.4s ease-in;
    z-index: z(over-default);

    @include media-breakpoint(xs) {
        position: fixed;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100vw;
        padding: 0;
        border-radius: 0;
        background: $bg-overlay;
        transform: translateY(-100vh);
        z-index: z(modal-overlay);
    }
}

.ea-launcher-panel {
    width: 100%;

    @include media-breakpoint(xs) {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        max-width: sizer(41);
        height: calc(100% - #{sizer(3)});
        min-height: rem(320px);
        max-height: rem(450px);
        padding: sizer(2) sizer(2.5);
        border-radius: $br-big;
        background: $bg-secondary;

        .ea-select-multiple__input-container {
            flex-direction: column;
            width: 100%;

            .ea-selector-component {
                margin-bottom: sizer(2);
            }

            .ea-button-component {
                margin-left: 0;
            }
        }
    }

    &__header {
        display: none;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: sizer(2);

        @include media-breakpoint(xs) {
            display: flex;
        }

        .ea-icon-component {
            color: $fc-action;
            margin-left: auto;
        }
    }

    &__mobile-button {
        cursor: pointer;
        display: none;
        margin-top: auto;
        width: 100%;

        @include media-breakpoint(xs) {
            display: block;
        }
    }

    &__clean-data {
        display: none;
        color: $fc-action;
        font-size: fs(xxs);
        cursor: pointer;

        @include media-breakpoint(xs) {
            display: block;
        }
    }

    &__date {
        display: none;
        align-items: center;
        justify-content: center;
        width: 100%;

        @include media-breakpoint(xs) {
            display: flex;
        }

        &-item {
            font-size: fs(xs);
            font-weight: $fw-extrabold;
            color: $fc-action;
            flex-basis: 50%;
            white-space: nowrap;
            text-align: center;
            border-bottom: rem(2px) solid transparent;
            padding-bottom: sizer(0.5);

            &.is-focused {
                border-bottom-color: $fc-action;
            }
        }
    }

    &__step {
        display: none;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        &-title {
            font-size: fs(xm);
            margin-bottom: sizer(2);
            color: $c-brand-second;

            @include media-breakpoint(xs) {
                font-size: fs(s);
                text-align: center;
            }

            &--mobile {
                @include media-breakpoint(xs) {
                    display: none;
                }
            }
        }

        &.is-visible {
            display: flex;
        }
    }
}

.es-launcher-pages {
    margin-bottom: sizer(3);

    .ea-launcher-container__title {
        padding: sizer(1.5) 0;
        text-align: center;
        font-size: fs(xxm);
        color: $fc-heading;
        margin: 0;

        &::before {
            display: none;
        }
    }
}

.ea-launcher-modality-options {
    padding: 1rem;

    &__nav {
        display: flex;
        padding-top: sizer(1);
    }

    &__btn {
        flex: 1;
        padding: 0 sizer(1.5);

        &:first-child {
            padding-left: sizer(4);
        }

        &:last-child {
            padding-right: sizer(4);
        }

        .ea-radio {
            display: flex;
            width: 100%;
            padding-bottom: sizer(2);

            &__value {
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: $ff-secondary;
                font-weight: $fw-bold;
                padding-left: 0;
                border-radius: $br-small;
                border: rem(2px) solid $c-grey;
                color: $fc-action;
                background: $c-bright;

                @include size(100%, sizer(5.5));

                &::before,
                &::after {
                    display: none;
                }
            }

            &__input {
                &:checked {
                    + .ea-radio__value {
                        border-color: $c-brand-third;
                        color: $c-bright;
                        background: $c-brand-third;
                    }
                }
            }
        }
    }

    &__caption {
        padding: sizer(2.5) sizer(3);
        color: $c-grey-font-disabled;
        background: $c-grey-lightest;
        border-radius: $br-big;
        transition: all 0.15s ease-in;

        p {
            font-size: fs(xxs);
        }
    }
}
