//to override external components without too much code.

// ## React datepicker
.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker {
    border: 0;
    border-radius: sizer(1);
    font-family: $ff-base;
    padding: 0 sizer(2);

    @include shadow(soft);

    &__triangle {
        display: none;
    }

    &__header {
        background: transparent;
        border-bottom: 0;
        display: flex;
        flex-direction: column;
        padding: 0;

        &__dropdown--select {
            order: -1;
            padding: sizer(1.5) 0 sizer(2) 0;
        }

        select {
            @extend %form-base;

            -webkit-appearance: none;
            font-weight: $fw-semibold;
            height: auto;
            width: auto;
            padding: sizer(0.5) sizer(1);
            padding-right: sizer(4);
            color: $fc-action;
            background: url('../../icons/ic_chevron_down.svg') no-repeat right center;

            &:hover {
                border-color: $fc-action;
                cursor: pointer;
            }
        }
    }

    &__current-month {
        position: relative;
        font-family: $ff-secondary;
        font-size: fs(s);
        font-weight: $fw-extrabold;
        text-transform: capitalize;
        padding-bottom: sizer(1.5);
        color: $c-brand-second;
    }

    &__navigation {
        top: sizer(8.5);
        border: none;
        transition: all ease 0.3s;

        @include square(sizer(2));

        &:hover {
            filter: brightness(1.5);
        }

        &--previous {
            left: sizer(4);
            background: url('../../icons/icon-set/icon-chevron-left.svg') no-repeat center;
        }

        &--next {
            right: sizer(4);
            background: url('../../icons/icon-set/icon-chevron-right.svg') no-repeat center;
        }
    }

    &__month {
        margin: 0;
    }

    &__day {
        --day-color: #{$fc-heading};
        --day-bg-color: none;
        --day-outer-bg-color: none;
        --day-border-radius: 0;

        height: sizer(3.55);
        width: sizer(3.55);
        font-weight: $fw-semibold;
        margin: 0 !important;
        z-index: z(over-default);
        position: relative;
        color: var(--day-color) !important;
        border-radius: var(--day-border-radius) !important;
        background-color: var(--day-outer-bg-color) !important;

        &::before {
            content: '';
            display: block;
            position: absolute;
            background: var(--day-bg-color);
            overflow: hidden;
            border-radius: 100%;
            z-index: z(below);
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
        }

        &-names {
            order: 3;
        }

        &-name {
            font-weight: $fw-semibold;
            text-transform: capitalize;
            margin: 0 !important;
            color: $fc-quarter;
        }

        &--today {
            --day-color: #485cc7;
            --day-bg-color: #e9f0f8;
            --day-border-radius: 100%;

            &.react-datepicker__day--disabled {
                filter: grayscale(1);
            }
        }

        &--outside-month {
            --day-color: #{$c-grey};

            visibility: hidden;
        }

        &--disabled {
            --day-color: #{$c-grey};

            user-select: none;
            pointer-events: none;
        }

        &:hover,
        &--keyboard-selected {
            --day-color: #485cc7;
            --day-bg-color: #ccdaeb;
            --day-border-radius: 100%;
        }

        &--selected {
            --day-color: #{$fc-negative};
            --day-bg-color: #{$c-brand-second};
        }

        &--in-range {
            --day-color: #{$fc-action};
            --day-outer-bg-color: #{$c-brand-third};
            --day-border-radius: none !important;

            &.react-datepicker__day--range-start {
                --day-border-radius: 100% 0 0 100% !important;
                --day-color: #{$fc-negative};
                --day-bg-color: #{$c-brand-second};
            }

            &.react-datepicker__day--range-end {
                --day-border-radius: 0 100% 100% 0 !important;
                --day-color: #{$fc-negative};
                --day-bg-color: #{$c-brand-second};
            }

            &.react-datepicker__day--range-start.react-datepicker__day--range-end {
                --day-border-radius: 100% !important;
            }
        }

        &--in-selecting-range {
            &.react-datepicker__day--in-range.react-datepicker__day--selecting-range-end:not(.react-datepicker__day--range-end) {
                --day-border-radius: none !important;
            }

            &:not(.react-datepicker__day--in-range):not(.react-datepicker__day--outside-range) {
                --day-color: #{$fc-action};
                --day-outer-bg-color: #{$c-brand-third};
                --day-border-radius: none !important;

                &.react-datepicker__day--selecting-range-start {
                    --day-border-radius: 100% 0 0 100% !important;
                    --day-color: #{$fc-negative};
                    --day-bg-color: #{$c-brand-second};
                }

                &.react-datepicker__day--selecting-range-end {
                    --day-border-radius: 0 100% 100% 0 !important;
                    --day-color: #485cc7;
                    --day-bg-color: #ccdaeb;
                }
            }
        }
    }
}

// React table component
table {
    th {
        text-align: left;
    }
}

// ## Table
.ea-react-table-component {
    border-radius: $br-big $br-big 0 0;

    .ea-react-table {
        width: 100%;
        border-radius: $br-big;

        &__thead {
            color: $fc-negative;
        }

        &__tr {
            &:nth-child(even) {
                .ea-react-table__td {
                    background: $bg-heading-light;
                }
            }
        }

        &__th {
            vertical-align: middle;
            height: sizer(6);
            font-weight: inherit;
            padding: 0 sizer(3);
            text-align: left;
            background: $bg-heading;

            &:first-child {
                border-radius: sizer(1) 0 0 0;
            }

            &:last-child {
                border-radius: 0 sizer(1) 0 0;
            }
        }

        &__td {
            position: relative;
            vertical-align: middle;
            padding: 0 sizer(3);
            height: sizer(6);

            .ea-button-component {
                color: $c-grey-dark;
                border-color: transparent;
                background: transparent;

                &:hover {
                    color: $fc-heading;
                    background: $c-grey-light;
                }
            }

            &:first-child {
                border-radius: $br-big 0 0 $br-big;
            }

            &:last-child {
                border-radius: 0 $br-big $br-big 0;
            }
        }

        &__tbody {
            font-size: fs(xs);
            text-align: left;
        }

        .pagination-bottom {
            display: flex;
            justify-content: flex-end;
        }
    }

    & + .ea-pagination-component {
        justify-content: flex-end;
        margin-top: sizer(4);
    }
}

// ## Tooltips
.__react_component_tooltip {
    --color-warning: #ff8100;
    --bg-warning: #fff6ad;
    --bg-error: #ffefef;

    max-width: sizer(30);
    border-radius: $br-big;

    @include shadow(dropdown);

    &.type-error {
        color: $c-red !important;
        background: var(--bg-error) !important;
    }

    &.type-warning {
        color: var(--color-warning) !important;
        background: var(--bg-warning) !important;
    }

    &.show {
        opacity: 1;
    }

    &.place-right::after {
        display: none;
    }
}
