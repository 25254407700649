.ea-chip {
    --c-base: #{$fc-negative};
    --bg-base: #{$c-brand-third};

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: sizer(1) sizer(2);
    border-radius: $br-big;
    background: var(--bg-base);

    &__value {
        color: $fc-negative;
    }

    &__btn {
        --bg-base: transparent;
        --bc-base: transparent;

        margin-left: sizer(1);
    }
}
