@import '@assets/scss/utils.scss';

.ea-form-dynamic {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;
    margin-left: sizer(-1.5);

    @include media-breakpoint(sm) {
        flex-direction: column;
        margin-left: 0;
    }

    .ea-textfield-component {
        padding-left: sizer(1.5);
        padding-bottom: sizer(3.5);

        @include media-breakpoint(sm) {
            width: 100%;
            padding-left: 0;
        }
    }

    .ea-select-multiple__input-container {
        @include media-breakpoint(xs) {
            flex-direction: column;

            .ea-selector-component {
                margin-bottom: sizer(2);
            }

            .ea-button-component {
                margin-left: 0;
            }
        }
    }
}
